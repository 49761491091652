import React, { useState } from "react";
import VideoBg from "@/components/VideoBg";

import { ContentProvider } from "@/context/ContentContext";
import classNames from "classnames";

interface RootLayoutProps {
  children: React.ReactNode;
}

const RootLayout: React.FC<RootLayoutProps> = ({ children }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  return (
    <main className="relative h-screen w-full overflow-hidden">
      <ContentProvider>
        <VideoBg
          isVideoLoaded={isVideoLoaded}
          setIsVideoLoaded={setIsVideoLoaded}
        />
        {isVideoLoaded && <div className="relative z-10">{children}</div>}
      </ContentProvider>
    </main>
  );
};

export default RootLayout;
