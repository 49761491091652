import React, { createContext, useContext, useState, ReactNode } from "react";
import { ContentKey } from "@/constants";
interface ContentContextProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isExpandBottom: boolean;
  setIsExpandBottom: (isExpandBottom: boolean) => void;
  selectedContent: ContentKey | null;
  setSelectedContent: (selectedContent: ContentKey | null) => void;
  previousRoute: string;
  setPreviousRoute: (route: string) => void;
}

const ContentContext = createContext<ContentContextProps | undefined>(
  undefined,
);

export const ContentProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isExpandBottom, setIsExpandBottom] = useState<boolean>(false);
  const [selectedContent, setSelectedContent] = useState<ContentKey | null>(
    null,
  );
  const [previousRoute, setPreviousRoute] = useState<string>("");

  return (
    <ContentContext.Provider
      value={{
        isOpen,
        isExpandBottom,
        selectedContent,
        setIsOpen,
        setIsExpandBottom,
        setSelectedContent,
        previousRoute,
        setPreviousRoute,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};

export const useContentContext = () => {
  if (typeof window === "undefined") {
    return {
      isOpen: false,
      setIsOpen: () => {},
      isExpandBottom: false,
      setIsExpandBottom: () => {},
      selectedContent: null,
      setSelectedContent: () => {},
      previousRoute: "",
      setPreviousRoute: () => {},
    };
  }

  const context = useContext(ContentContext);
  if (!context) {
    throw new Error("useContext must be wrapped in a Provider");
  }
  return context;
};
