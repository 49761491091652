import React from "react";
import RootLayout from "./src/layout/index";
import DetailLayout from "./src/layout/detail";
import { ContentProvider } from "@/context/ContentContext";

import "./src/styles/global.css";

export const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props;
  const Layout = pageContext.slug ? DetailLayout : RootLayout;

  return (
    <ContentProvider>
      <Layout {...props}>{element}</Layout>
    </ContentProvider>
  );
};
