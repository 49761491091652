import React from "react";

import { ContentProvider } from "@/context/ContentContext";

interface DetailLayoutProps {
  children: React.ReactNode;
}

const DetailLayout: React.FC<DetailLayoutProps> = ({ children }) => {
  return (
    <main className="relative h-screen w-full overflow-hidden">
      <ContentProvider>{children}</ContentProvider>
    </main>
  );
};

export default DetailLayout;
