const SCREEN_WIDTHS = {
  MOBILE: 640,
  TABLET: 768,
  DESKTOP: 1024,
  LARGE_DESKTOP: 1440,
};

const MAXIMUM_UPLOADED_FILE_SIZE = 2 * 1024 * 1024;

const ROUTES = {
  HOME: "/",
  CAREERS: "/careers",
  ABOUT_US: "/about-us",
  PRIVACY_POLICY: "/privacy-policy",
};

enum ContentKey {
  Career = "career",
  AboutUs = "aboutUs",
  Privacy = "privacy",
}

const EXTERNAL_LINKS = {
  LINKEDIN: "https://sg.linkedin.com/company/nova-prospect-ltd",
};

const EMAIL_ADDRESS = "careers@novaprospect.com";

export {
  SCREEN_WIDTHS,
  EXTERNAL_LINKS,
  EMAIL_ADDRESS,
  ContentKey,
  ROUTES,
  MAXIMUM_UPLOADED_FILE_SIZE,
};
